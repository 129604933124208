import { Fragment, useEffect, useRef, useState } from "react"
import { Form } from "react-bootstrap"
import { useSelector } from "react-redux"
import ModalAtualizarUsuarios from "../../modal/ModalAtualizarUsuarios"

import './BodyRow.scss'
import { numerosEditadoBR } from "../../../services/Servicos"

export default function BodyRowDefault({ headList, bodyList, index }) {
    return (
        <Fragment>
            {
                <tr style={{backgroundColor:'grey', color:'white'}}>
                    {
                        headList.map((item, _index) => {

                            if (item === 'index') {
                                return <td key={_index} style={{color:'white'}}></td>

                            }
                            if (['peso_nf', 'peso', 'volume', 'qtde'].includes(item)) {
                                
                                return <td key={_index} className={item} style={{color:'white'}}> 
                                    {numerosEditadoBR(bodyList[item])}
                                </td>
                            }
                            return <td key={_index} className={item} style={{color:'white'}}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
        </Fragment>
    )
}