import axios from "axios";
import { clearPersistData } from "../redux/storeConfig/store";

export const locationDevNew = () => {
	const url = window.location.href;

	if (url.includes("b-log-dev")) {
		return process.env.REACT_APP_URL_SERVER_DEV;
	}
	if (url.includes("b-log")) {
		return process.env.REACT_APP_URL_SERVER;
	}
	return `${window.location.origin.replace("81", "80")}`;
};
// export const locationDev = process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_URL_SERVER : `${window.location.origin.replace('81', '80')}`
export const locationDev = locationDevNew();

export function getImages(src) {
	return `${locationDev}/${src}`;
}
export async function getDanfe(xmlFile) {
	return await axios({
		method: "post",
		url: `https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API`,
		headers: {
			"Content-Type": "text/plain",
		},
		data: xmlFile,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			let errorObj = { error: true, msg: error.message };
			return errorObj;
		});
}
export async function getDanfeVST(id_filial, numero_pedido) {
	return await axios({
		method: "get",
		url: `https://logistica.vstsolution.com/logisticadanfe/1/id_filial/${id_filial}/id_saida/${numero_pedido}`,
		responseType: "arraybuffer",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/pdf",
		},
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
}

export async function insertInto(route, body) {
	return await axios({
		method: "post",
		url: `${locationDev}/${route}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: body,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response?.status === 401) {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw error;
		});
}
export async function getTableColumns(id, req) {
	return await axios({
		method: "get",
		url: `${locationDev}/table/${id}`,
		// headers: {
		//     // "Authorization": `JWT ${localStorage.getItem('token')}`
		// }
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			let errorObj = {};
			if (error.message.toLowerCase() === "network error") {
				errorObj = { error: true, msg: error.message.toLowerCase() };
			}
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}

export async function getToken(user) {
	return await axios({
		method: "post",
		url: `${locationDev}/token`,
		data: user,
	});
}

export async function login(user) {
	return await getToken(user)
		.then((response) => {
			if (response.data.token) {
				return axios({
					method: "get",
					url: `${locationDev}/${user.tipo}`,
					headers: {
						Authorization: `JWT ${response.data.token}`,
					},
				})
					.then((_response) => {
						const tipo = _response.data.tipo ? _response.data.tipo : user.tipo;
						if (_response.data.email) {
							const _user = {
								..._response.data,
								token: response.data.token,
								tipo,
							};

							return { user: _user };
						}
					})
					.catch((error) => {
						throw error;
					});
			}
		})
		.catch((error) => {
			let errorObj = {};
			if (error.message === "Network Error") {
				errorObj = { msg: "Server" };
			} else if (error) {
				errorObj = { msg: error.response.data.credentialsError };
			}
			throw errorObj;
		});
}

export async function createCompany(user) {
	return await axios({
		method: "post",
		url: `${locationDev}/empresas`,
		data: user,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			}

			throw errorObj;
		});
}
export async function savalDanfeAssinada(body) {
	return await axios({
		method: "post",
		url: `${locationDev}/danfe`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
			"Content-Type": "multipart/form-data",
		},
		data: body,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}
export async function createDailyReport(body) {
	return await axios({
		method: "post",
		url: `${locationDev}/diarios`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
			"Content-Type": "multipart/form-data",
		},
		data: body,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			throw error;
		});
}
export async function uploadImg(url, params1, params2, body) {
	return await axios({
		method: "post",
		url: `${locationDev}/${url}/${params1}/${params2}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
			"Content-Type": "multipart/form-data",
		},
		data: body,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			throw error;
		});
}

export async function createFuncionario(user) {
	return await axios({
		method: "post",
		url: `${locationDev}/funcionarios`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
			"Content-Type": "multipart/form-data",
		},
		data: user,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function createCaminhao(user, userT) {
	const stringGet =
		userT.toLowerCase() !== "gestor" ? "caminhoes" : "gestao/caminhoes";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: user,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};

			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw errorObj;
		});
}

export async function createRotas(rota, userT) {
	const stringGet = userT.toLowerCase() !== "gestor" ? "rotas" : "gestao/rotas";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: rota,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				throw error;
			} else if (error.response?.data?.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw errorObj;
		});
}

export async function ajustePontosEmpresa(ponto) {
	return await axios({
		method: "post",
		url: `${locationDev}/empresa/controle-ponto`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: ponto,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw errorObj;
		});
}

export async function createPontos(ponto) {
	return await axios({
		method: "post",
		url: `${locationDev}/funcionario/controle-ponto`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: ponto,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw errorObj;
		});
}

export async function createCarga(carga, userTipo) {
	return await axios({
		method: "post",
		url: `${locationDev}/cargas`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: carga,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data?.msg) {
				throw error;
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}

export async function confirmarTokenAdicional(body) {
	return await axios({
		method: "put",
		url: `${locationDev}/cargas/financeiro-adicional-credito`,
		data: body,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}

export async function confirmarToken(body) {
	return await axios({
		method: "put",
		url: `${locationDev}/cargas/financeiro-confirmacao`,
		data: body,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}
export async function confirmarTokenContabilidade(body) {
	return await axios({
		method: "put",
		url: `${locationDev}/cargas/financeiro/confirmacao/gestor`,
		data: body,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}

export async function createEmbalagem(embalagem, userT) {
	const stringGet = "cargas/funcionarios/embalagens";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: embalagem,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}
export async function createEmbalagemTransf(embalagem) {
	return await axios({
		method: "post",
		url: `${locationDev}/transferencias/funcionarios/embalagens`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: embalagem,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}

export async function createMsg(file, userT) {
	const stringGet = "cargas/produtos/financeiro-sms/pdf"

	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
			"Content-Type": "multipart/form-data",
		},
		data: file,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response?.status === 401) {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw error;
		});
}

export async function createDespesa(body, user) {
	const stringGet = "despesas";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
			"Content-Type": "application/json",
		},
		data: body,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			if (error.response?.status === 401) {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw error;
		});
}

export async function createFreteiro(body, user) {
	const stringGet =
		user.toLowerCase() !== "gestor"
			? "caminhoes/freteiro"
			: "gestao/caminhoes/freteiro";

	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: body,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}
export async function createAbastecimento(body, user) {
	const stringGet = "abastecimentos"

	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
			"Content-Type": "multipart/form-data",
		},
		data: body,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}

export async function insertPedidosManual(pedido, user) {
	const stringGet = "pedidos/manual";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: pedido,
	})
}

export async function insertPedidos(pedido, user) {
	const stringGet = "cargas/pedidos/empresa";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: pedido,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			if (error.response?.status == "400") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw error.message;
		});
}

export async function createProdutosFromSeparacao(produto) {
	return await axios({
		method: "post",
		url: `${locationDev}/cargas/funcionarios/produtos`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: produto,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}
export async function insertProdutos(produto, user) {
	const stringGet =
		user.toLowerCase() !== "gestor"
			? "cargas/produtos"
			: "gestao/cargas/produtos";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: produto,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}

export async function gestaoGetFuncionarios(user) {
	return await axios({
		method: "get",
		url: `${locationDev}/gestao/funcionarios`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			let errorObj = {};
			// alert('ERROR Funcionarios!!!!!')
			errorObj = { msg: error.response.data };
			throw errorObj;
		});
}

export async function getFuncionarios(user) {
	return await axios({
		method: "get",
		url: `${locationDev}/funcionarios`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			let errorObj = {};
			// alert('ERROR Funcionarios!!!!!')
			errorObj = { msg: error.response.data };
			throw errorObj;
		});
}

export async function getFuncionario(user) {
	return await axios({
		method: "get",
		url: `${locationDev}/funcionario`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			let errorObj = {};
			// alert('ERROR Funcionarios!!!!!')
			errorObj = { msg: error.response.data };
			throw errorObj;
		});
}

export async function updateEquipamento(user, body, id) {
	const stringGet =
		user.toLowerCase() !== "gestor" ? "equipamentos" : "gestao/equipamentos";
	return await axios({
		method: "put",
		url: `${locationDev}/${stringGet}/${id}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: body,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			if (error?.response?.data?.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw error;
		});
}
export async function createEquipamento(user, body) {
	const stringGet =
		user.toLowerCase() !== "gestor" ? "equipamentos" : "gestao/equipamentos";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: body,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			if (error?.response?.data?.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw error;
		});
}
export async function getEquipamentos(user) {
	const stringGet =
		user.toLowerCase() !== "gestor" ? "equipamentos" : "gestao/equipamentos";
	return await axios({
		method: "get",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			if (
				typeof error?.response?.data == "string" &&
				error?.response?.data.toLowerCase() === "unauthorized"
			) {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw error;
		});
}

export async function insertProdutosTransferencia(produto, user) {
	const stringGet =
		user.toLowerCase() !== "gestor"
			? "transferencias/add-produto"
			: "gestao/transferencias/add-produto";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: produto,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}

			throw errorObj;
		});
}

export async function createTransferencia(user, body) {
	const stringGet = "transferencias"

	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: body,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			if (error?.response?.data?.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw error;
		});
}
export async function getTransferencias(query) {
	const stringGet = "transferencias";
	return await axios({
		method: "get",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		params: query,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			if (
				typeof error?.response?.data == "string" &&
				error?.response?.data.toLowerCase() === "unauthorized"
			) {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw error;
		});
}

export async function getCaminhoes(user) {
	const stringGet = "caminhoes";
	return await axios({
		method: "get",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			let errorObj = {};
			// alert('ERROR Caminhoes!!!!!')
			errorObj = { msg: error.response.data };
			throw errorObj;
		});
}

export async function getRotas(user, id_filial) {
	const stringGet = user.toLowerCase() !== "gestor" ? "rotas" : "gestao/rotas";
	return await axios({
		method: "get",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: {
			id_filial,
		},
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			return errorObj;
		});
}

export async function deleteData(to) {
	return await axios({
		method: "delete",
		url: `${locationDev}/${to}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
	})
		.then((response) => {
			return response
		})
		.catch((error) => {
			
			if (error.status == '401') {
				clearPersistData();
				return window.location.replace("/login");
			}
			
			throw error;
		});
}

export async function deleteFoto(to, id, imgSource) {
	return await axios({
		method: "delete",
		url: `${locationDev}/${to}/${id}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: { fileName: imgSource },
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw errorObj;
		});
}

export async function getAllData(to, data, extraConfigs) {
	return await axios({
		method: "get",
		url: `${locationDev}/${to}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		params: data,
		...extraConfigs
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response?.status === 401) {
				clearPersistData();
				return window.location.replace("/login");
			}
			if (error.message == "Network Error") {
				error.message =
					"Sem resposta do servidor. Entre em contato com o suporte";
			}

			throw error;
		});
}
export async function getAllDataN(to, data) {
	return await axios({
		method: "get",
		url: `${locationDev}/${to}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		params: data,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response?.status === 401) {
				clearPersistData();
				return window.location.replace("/login");
			}
			if (error.message == "Network Error") {
				error.message =
					"Sem resposta do servidor. Entre em contato com o suporte";
			}

			throw error;
		});
}

export async function getData(to, id, data) {
	return await axios({
		method: "get",
		url: `${locationDev}/${to}/${id}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		params: data,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response?.status === 401) {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw error;
		});
}
export async function verificarPedido(to, id, data) {
	return await axios({
		method: "get",
		url: `${locationDev}/${to}/${id}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		params: data,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function alterData(to, id, user) {
	return await axios({
		method: "put",
		url: `${locationDev}/${to}/${id}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
			"Content-Type": "multipart/form-data",
		},
		data: user,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw errorObj;
		});
}

export async function updateTableNew(to, data) {
	return await axios({
		method: "put",
		url: `${locationDev}/${to}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: data,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
}
export async function updateTableNewWithParams(to, id, data) {
	return await axios({
		method: "put",
		url: `${locationDev}/${to}/${id}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: data,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
}
export async function updateTable(to, id, data) {
	return await axios({
		method: "put",
		url: `${locationDev}/${to}/${id}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: data,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			if (error.response?.status === 401) {
				clearPersistData();
				return window.location.replace("/login");
			}
			if (error.message == "Network Error") {
				error.message =
					"Sem resposta do servidor. Entre em contato com o suporte";
			}

			throw error;
		});
}

export async function alterFuncionarios_Caminhoes(to, id, user) {
	return await axios({
		method: "put",
		url: `${locationDev}/${to}/${id}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: user,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			} else if (error.response.data.toLowerCase() === "unauthorized") {
				clearPersistData();
				return window.location.replace("/login");
			}
			throw errorObj;
		});
}

export async function setOnline(user, socket) {
	const joinUser = {
		user_email: user.email,
		user_id: user.id,
		user_cpf: user.cpf,
		user_id_socket: socket.id,
		user_tipo: user.tipo,
	};
	if (user.tipo.toLowerCase() === "empresa") {
		socket.emit("joinPrivateRoom", { user: joinUser });
		return;
	}
	socket.emit("joinRoom", { user: joinUser });
	return;
}
export async function setPrivateSocket(user, socket) {
	const joinUser = {
		user_tipo: "empresa",
		user_id_socket: socket.id,
	};
	socket.emit("joinRoom", { user: joinUser });
	return;
}

export async function resetPassword(user) {
	return await axios({
		method: "post",
		url: `${locationDev}/funcionarios/recuperar-senha`,
		data: user,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			let errorObj = {};
			errorObj = { msg: error.response.data };
			throw errorObj;
		});
}

export async function saveNewPassword(user) {
	return await axios({
		method: "post",
		url: `${locationDev}/funcionarios/reset-senha`,
		data: user,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			let errorObj = {};
			errorObj = { msg: error.response.data };
			throw errorObj;
		});
}

export async function insertLogCaminhoes(body, userT) {
	const stringGet =
		userT.toLowerCase() !== "gestor"
			? "caminhoes/caminhoes-log"
			: "gestao/caminhoes/caminhoes-log";
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: body,
	})
		.then((response) => {
			return { error: false, msg: "none error" };
		})
		.catch((error) => {
			let errorObj = {};
			if (error.response.data.msg) {
				errorObj = { error: true, msg: error.response.data.msg };
			}

			throw errorObj;
		});
}
export async function getRoutesGoogleMaps(params) {
	/* 
        params -> origem, destino, language, units: metric
    */
	return await axios({
		method: "get",
		url: `${process.env.REACT_APP_URL_DIRECTIONS_MAPS}`,
		params: {
			key: process.env.REACT_APP_MAP_KEY,
			language: "pt-br",
			units: "metric",
			origin: params.origin,
			destination: params.destination,
			waypoints: params.waypoints ? params.waypoints : "",
		},
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function getXMLString({ id_filial, numero_pedido }) {
	return await axios({
		method: "get",
		url: `${process.env.REACT_APP_API_VST_XML}/id_filial/${id_filial}/id_saida/${numero_pedido}`,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
}

export async function getAuthArtos() {
	return await axios({
		method: "post",
		url: "https://api-integracao.artossoftware.com.br/auth/login",
		headers: {
			Accept: "application/json",
		},
		data: {
			email: "joseadoes@gmail.com",
			password: "ckBv523PjR",
		},
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
}

export async function registerManifestoArtos(auth, body) {
	return await axios({
		method: "post",
		url: "https://api-integracao.artossoftware.com.br/v1/mdfe/register",
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${auth}`,
		},
		data: body,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
}

export async function getManifestoFileArtos(token, manifesto_id) {
	return await axios({
		method: "get",
		url: `https://api-integracao.artossoftware.com.br/v1/mdfe/${manifesto_id}/pdf`,
		responseType: "arraybuffer",
		headers: {
			// "Accept": 'application/json',
			"Content-Type": "application/json",
			Accept: "application/pdf",
		},
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}
export async function getImagesFromServer(url) {
	return await axios({
		method: "get",
		url: url,
		responseType: "arraybuffer",
		headers: {
			// "Accept": 'application/json',
			// "Authorization": `Bearer ${token}`,
			"Content-Type": "application/json",
			Accept: "image/png",
		},
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function cancelarManifestoFunc(id) {
	return await axios({
		method: "get",
		url: `${locationDev}/manifesto/carga/cancelar/${id}`,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error.data || error.response.data;
		});
}
export async function encerrarManifestoFunc(id) {
	return await axios({
		method: "get",
		url: `${locationDev}/manifesto/carga/encerrar/${id}`,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
}
export async function encerrarManifestoByProtocolo(data) {
	return await axios({
		method: "put",
		url: `${locationDev}/manifesto/protocolo/encerrar`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: data,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}
export async function getManifestoPDF(id) {
	return await axios({
		method: "get",
		url: `${locationDev}/manifesto/carga/pdf/${id}`,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error.data || error.response.data;
		});
}

export async function getManifestoStatus(id) {
	return await axios({
		method: "get",
		url: `${locationDev}/manifesto/carga/status/${id}`,
	})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error?.data || error?.response?.data || error;
		});
}
export async function getManifestoId(cargas_id, userTipo) {
	const stringGet =
		userTipo.toLowerCase() !== "empresa"
			? `manifesto/carga/funcionario/${cargas_id}`
			: `manifestos/carga/empresa/${cargas_id}`;
	return await axios({
		method: "get",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function createManifesto(body, userTipo) {
	const stringGet =
		userTipo.toLowerCase() !== "empresa"
			? `manifesto/carga/funcionario`
			: `manifesto/carga/empresa`;
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: body,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function createManifestoV2(body, userTipo) {
	const stringGet = 'manifesto/v2/gerar-mdfe'
	return await axios({
		method: "post",
		url: `${locationDev}/${stringGet}`,
		headers: {
			Authorization: `JWT ${localStorage.getItem("token")}`,
		},
		data: body,
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export function correctDataHoursUTC(value) {
	return new Date(value).toLocaleString("pt-br", { timeZone: "UTC" });
}

export function correctDataUTC(value) {
	return new Date(value).toLocaleDateString("pt-br", { timeZone: "UTC" });
}

export function apenasNumeros(value) {
	return value.replace(/\D/gim, "");
}

export function numerosDecimal(value) {
	return value.replace(",", "").replace(/(\d{1,2})$/, ",$1");
}

export function numerosDecimal3(value) {
	return value
		.replace(/\D/g, "")
		.replace(",", "")
		.replace(/(\d{1,3})$/, ",$1");
}

export function numerosEditadoBR(value) {
	return Number(Number(value).toFixed(3)).toLocaleString("pt-br");
	// return Number(Number(value)*0.01).toLocaleString('pt-br',{minimumFractionDigits:2})
}
export function numeroDecimais(value) {
	return Number(Number(value) * 0.01).toLocaleString("pt-br", {
		minimumFractionDigits: 2,
	});
}
export function numerosInteiros(value) {
	// return Number(Number(value).toFixed(3)).toLocaleString('pt-br')
	return Number(Number(value)).toLocaleString("pt-br", {
		minimumFractionDigits: 0,
	});
}

export function Unauthorized(dispatch, update) {
	// alert('Session has Expired')
	dispatch(update({ authenticated: false, token: "" }));
	window.location.replace("/login");
}

export function getDateInit(date = new Date()) {
	let day = date.getDate();
	let month = date.getMonth() + 1;
	let year = date.getFullYear();

	if (day <= 9) {
		day = `0${day}`;
	} else if (month <= 9) {
		month = `0${month}`;
	}

	return {
		data_inicial: `${year}-${String(month).padStart(2, 0)}-01`,
		data_final: `${year}-${String(month).padStart(2, 0)}-${day}`,
	};
}
export function getDateInitMonth(date = new Date()) {
	let day = Number(date.getDate()).toLocaleString("pt-BR", {
		maximumSignificantDigits: 2,
	});
	let month = Number(date.getMonth() + 1).toLocaleString("pt-BR", {
		maximumSignificantDigits: 2,
	});
	let year = date.getFullYear();

	return {
		data_inicial: `${year}-${month}-01`,
		data_final: `${year}-${month}-${day}`,
	};
}
export function getDateInitSEMANAL(date) {
	let day_today = date.getDate();
	let month = date.getMonth() + 1;
	let year = date.getFullYear();

	date.setDate(date.getDate() - 7);
	let day_last_week = date.getDate();
	let month_last_week = date.getMonth() + 1;
	let year_last_week = date.getFullYear();
	return {
		data_final: `${year}-${month}-${day_today}`,
		data_inicial: `${year_last_week}-${month_last_week}-${day_last_week}`,
	};
}

export const valorMoeda = (valor) =>
	Number(valor).toLocaleString("pt-br", { style: "currency", currency: "BRL" });

export const getTimesAgo = (time) => {
	const timer = new Date(new Date().getTime() - new Date(time).getTime());
	const daysDiff = dateDiffInDays(new Date(time), new Date());

	function dateDiffInDays(a, b) {
		const _MS_PER_DAY = 1000 * 60 * 60 * 24;
		// Discard the time and time-zone information.
		const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
		return Math.floor((utc2 - utc1) / _MS_PER_DAY);
	}

	const [horas, minutos] = timer
		.toLocaleTimeString("pt-br", { timeZone: "UTC" })
		.split(":");

	if (daysDiff < 1 && Number(horas) === 0) {
		return `${Number(minutos)} minutos atrás`;
	}
	if (daysDiff < 1 && Number(horas) >= 2) {
		return `${Number(horas)} horas atrás`;
	}
	if (daysDiff > 0) {
		return `${daysDiff} dias trás`;
	}
	return `${horas}:${minutos} horas atrás`;
};

export const getStatusCarga = (status) => {
	return [
		{ status: -4, titulo: "ROTAÇÃO DE PNEUS" },
		{ status: -3, titulo: "TACOGRAFO VENCIDO" },
		{ status: -2, titulo: "MANUTENÇÃO DO CAMINHÃO" },
		{ status: 0, titulo: "INDISPONIVEL" },
		{ status: 1, titulo: "DISPONIVEL PARA SEPARAÇÃO" },
		{ status: 2, titulo: "SEPARAÇÃO PARCIAL" },
		{ status: 3, titulo: "DISPONIVEL PLANEJAMENTO" },
		{ status: 4, titulo: "PLANEJAMENTO PARCIAL" },
		{ status: 5, titulo: "DISPONIVEL PARA FINANCEIRO" },
		{ status: 6, titulo: "AGUARDANDO APROVAÇÃO DO GESTOR" },
		{
			status: 7,
			titulo: "AGUARDANDO LIBERACAÇÃO DA CONTABILIDADE AO CAMINHONEIRO",
		},
		{ status: 8, titulo: "AGUARDANDO CONFIRMAÇÃO CAMINHONEIRO" },
		{ status: 9, titulo: "DISPONIVEL PARA CARREGAMENTO" },
		{ status: 10, titulo: "CARREGAMENTO PARCIAL" },
		{ status: 11, titulo: "DISPONIVEL PARA ENTREGA" },
		{ status: 12, titulo: "PARCIALMENTE ENTREGUE" },
		{ status: 13, titulo: "ENTREGUE" },
		{ status: 14, titulo: "FINALIZADO PELO FINANCEIRO" },
		{ status: 15, titulo: "ENTREGUE NA CIDADE" },
		{ status: 24, titulo: "FINALIZADO PELO GESTOR" },
	].filter((o) => o.status == status)[0]?.titulo;
};
export const getStatusTrackingPedidos = (status) => {
	const statusTracking = [
		{ status: -1, titulo: "DIPONIVEL PARA PLANEJAMENTO" },
		{ status: 0, titulo: "ATRIBUIDO A CARGA" },
		{ status: 1, titulo: "EM SEPARAÇÃO" },
		{ status: 3, titulo: "EM PLANEJAMENTO DE CARGA" },
		{ status: 9, titulo: "EM CARREGAMENTO" },
		{ status: 11, titulo: "DISPONIVEL PARA ENTEGA" },
		{ status: 12, titulo: "EM ROTA DE ENTREGA" },
		{ status: 13, titulo: "ENTREGUE" },
	].filter((o) => o.status <= status);
	return (
		statusTracking[statusTracking.length - 1]?.titulo || "ATRIBUIDO A CARGA"
	);
};
export const getStatusPedidos = (status) => {
	return [
		{ status: -3, titulo: "CARGA BLOQUEADA PELO VENCIMENTO DO TACOGRAFO" },
		{ status: -2, titulo: "CARGA BLOQUEADA PARA MANUTENÇÃO DO CAMINHÃO" },
		{ status: -1, titulo: "DISPONIVEL PARA PLANEJAMENTO" },
		{ status: 0, titulo: "EM PLANEJAMENTO" },
		{ status: 1, titulo: "DISPONIVEL PARA SEPARAÇÃO" },
		{ status: 2, titulo: "SEPARAÇÃO PARCIAL" },
		{ status: 3, titulo: "DISPONIVEL PLANEJAMENTO" },
		{ status: 4, titulo: "PLANEJAMENTO PARCIAL" },
		{ status: 5, titulo: "DISPONIVEL PARA FINANCEIRO" },
		{ status: 6, titulo: "AGUARDANDO APROVAÇÃO DO GESTOR" },
		{
			status: 7,
			titulo: "AGUARDANDO LIBERACAÇÃO DA CONTABILIDADE AO CAMINHONEIRO",
		},
		{ status: 8, titulo: "AGUARDANDO CONFIRMAÇÃO CAMINHONEIRO" },
		{ status: 9, titulo: "DISPONIVEL PARA CARREGAMENTO" },
		{ status: 10, titulo: "CARREGAMENTO PARCIAL" },
		{ status: 11, titulo: "DISPONIVEL PARA ENTREGA" },
		{ status: 12, titulo: "PARCIALMENTE ENTREGUE" },
		{ status: 13, titulo: "ENTREGUE" },
		{ status: 14, titulo: "FINALIZADO PELO FINANCEIRO" },
		{ status: 20, titulo: "FINALIZADO PELO GESTOR SEM CARGA" },
		{ status: 24, titulo: "DESCARREGADO NA LOJA" },
		{ status: 25, titulo: "RETIRAR NA LOJA" },
		{ status: 26, titulo: "CLIENTE RETIROU NA LOJA" },
	].filter((o) => o.status == status)[0]?.titulo;
};
export const getStatusPedidosTransferencia = (status) => {
	return [
		{ status: -1, titulo: "DISPONIVEL PARA PLANEJAMENTO" },
		{ status: 0, titulo: "DISPONIVEL PARA PLANEJAMENTO" },
		{ status: 1, titulo: "DISPONIVEL PARA SEPARAÇÃO" },
		{ status: 2, titulo: "SEPARAÇÃO PARCIAL" },
		{ status: 3, titulo: "DISPONIVEL PLANEJAMENTO" },
		{ status: 4, titulo: "PLANEJAMENTO PARCIAL" },
		{ status: 5, titulo: "DISPONIVEL PARA GESTOR DE CARGA" },
		{ status: 6, titulo: "INSERIDO PEDIDO DO VST" },
	].filter((o) => o.status == status)[0]?.titulo;
};

export const getFilialUsuario = (status) => {
	return [
		{ status: 0, titulo: "NÃO DEFINIDO" },
		{ status: 2, titulo: "FERMAT - CD" },
		{ status: 3, titulo: "FERMAT - TIJUCAL" },
		{ status: 4, titulo: "FERMAT - AGUA BOA" },
		{ status: 5, titulo: "FERMAT - CPA" },
		{ status: 6, titulo: "FERMAT - BEIRA RIO" },
		{ status: 7, titulo: "FERMAT - SAPEZAL" },
		{ status: 8, titulo: "FERMAT - MATRIZ" },
		{ status: 9, titulo: "FERMAT - SINPO" },
		{ status: 11, titulo: "FERMAT - LUCAS R" },
		{ status: 12, titulo: "FERMAT - ISOTAK" },
		{ status: 13, titulo: "FERMAT - INOXTAK" },
		{ status: 16, titulo: "FERMAT - TANGARA" },
		{ status: 17, titulo: "FERMAT - VG" },
	].filter((o) => o.status == status)[0]?.titulo;
};
export const getStatusBoolean = (status) => {
	return [
		{ status: 0, titulo: "INATIVO" },
		{ status: 1, titulo: "ATIVO" },
	].filter((o) => o.status == status)[0]?.titulo;
};

export const getCorretData = (date) => {
	return new Date(date).toLocaleDateString("pt-BR", { timeZone: "UTC" });
};

export const verificaTipoUsuario = (user) =>
	user.tipo.toLowerCase() === "empresa" ? "" : "gestao/";

export const telefone_mask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{2})(\d)/,'($1) $2')
    value = value.replace(/(\d)(\d{4})$/, '$1-$2')
    return value
};
export const cpf_mask = (string) => {
	return string
		.replace(/\D/g, "")
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d)/, "$1-$2");
	// .replace(/(\d{3})(\d{1,2})/, '$1-$2')
};
export const pis_mask = (string) => {
	return string
		.replace(/\D/g, "")
		.replace(/^(\d{3})(\d)/, "$1.$2") //Coloca ponto entre o terceiro e o quarto dígitos
		.replace(/^(\d{3})\.(\d{5})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
		.replace(/(\d{3})\.(\d{5})\.(\d{2})(\d)/, "$1.$2.$3-$4");
	// .replace(/(\d{3})(\d{1,2})/, '$1-$2')
};
export const cnpj_mask = (string) => {
	return string
		.replace(/\D/g, "")
		.replace(/^(\d{2})(\d)/, "$1.$2") //Coloca ponto entre o segundo e o terceiro dígitos
		.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
		.replace(/\.(\d{3})(\d)/, ".$1/$2") //Coloca uma barra entre o oitavo e o nono dígitos
		.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
};
export const cpf_cnpj_mask = (string) => {
	if (string.length === 11) {
		return string
			.replace(/(\d{3})(\d)/, "$1.$2")
			.replace(/(\d{3})(\d)/, "$1.$2")
			.replace(/(\d{3})(\d)/, "$1-$2");
	} else if (string.length >= 14) {
		return string
			.replace(/\D/g, "")
			.replace(/^(\d{2})(\d)/, "$1.$2") //Coloca ponto entre o segundo e o terceiro dígitos
			.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
			.replace(/\.(\d{3})(\d)/, ".$1/$2") //Coloca uma barra entre o oitavo e o nono dígitos
			.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
	}
	return string;
};

export function isMobile(stringAgent) {
	if (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			stringAgent
		)
	) {
		return true;
	}
	return false;
}

export async function getNotificacoes(body) {
	return await axios({
		method: "get",
		url: `${locationDev}/notificacoes`,
		params: body,
	})
		.then((response) => response)
		.catch((error) => {
			throw error;
		});
}

export const compareDates = (dataMenor, dataMaior, pedido) => {
	const daysDiff = dateDiffInDays(new Date(dataMenor), new Date(dataMaior));

	function dateDiffInDays(a, b) {
		const SECONDS_PER_DAY = 1000 * 60 * 60 * 24;
		// Discard the time and time-zone information.
		const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
		return Math.floor((utc2 - utc1) / SECONDS_PER_DAY);
	}
	return `${daysDiff}`;
};


// export const numberBR = (number) => Number
