import { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	getAllData,
	getDateInit,
	numerosEditadoBR,
} from "../../../services/Servicos";
import Chart from "react-apexcharts";
import { Button, Card, Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import BodyRowCargas from "../../table/body/BodyRowCargas";
import { filtroDinamicoArrays, removerDadosDuplicados, unicos } from "../../../services/helpers";

export default function GraficoTotalEntregueMotorista({
	title,
	unidadeMedida = "",
	posisaoUnidadeMedida,
	colors = "#f60809",
	arrayData,
	arrayDataFiltered,
	setArraydata = () => {},
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
				toolbar: {
					show: true,
				},
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					columnWidth: "100%",
					barHeight: "90%",
					dataLabels: {
						position: "top",
						// orientation: 'vertical',
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					if (posisaoUnidadeMedida == "frente") {
						return `${unidadeMedida} ${numerosEditadoBR(value)}`;
					}
					return `${numerosEditadoBR(value)} ${unidadeMedida} `;
				},
				offsetX: 20,
				style: {
					fontSize: "12px",
					colors: ["#000000"],
				},

				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
            },
            tooltip: {
                shared: true,
                intersect: false,
                style: {
                    fontSize: '10px',
                },
                fixed: {
                    enabled: false,
                    position: 'topLeft',
                    offsetX: 50,
                    offsetY: 30,
                },
            },
			xaxis: {
				labels: {
					show: false,
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: true,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value)}`;
							}
							return `${numerosEditadoBR(value)} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true)
		const resultado = arrayDataFiltered || arrayData;
		setArraydata(resultado);

		const motoristas = resultado.filter(
			(i, n, s) =>
				s.findIndex((o) => o.cpf_motorista === i.cpf_motorista) === n
		);

		const resultatoGeral = motoristas
			.map((item) => {
				const nomeMotorista = item.motorista.split(" ");
				let motorista = "";
				switch (nomeMotorista.length) {
					case 0:
						motorista = "N/A";
						break;
					case 1:
						motorista = item.motorista.split(" ")[0];
						break;
					default:
						motorista =
							item.motorista.split(" ")[0] +
							" " +
							item.motorista.split(" ")[1]; //?.slice(0, 3) + '...'
						break;
				}
				return {
					motorista,
					valorTotal: resultado.filter(
						(item2) => item2.cpf_motorista == item.cpf_motorista
					).length,
				};
			})
			.sort((a, b) => {
				if (a.valorTotal > b.valorTotal) return 1;
				if (a.valorTotal < b.valorTotal) return -1;
				return 0;
			})
			.filter((item) => item.valorTotal)
			.reverse();

		setOptions((last) => ({
			...last,
			options: {
				...last.options,
				colors: [colors],
				xaxis: {
					...last.options.xaxis,
					show: false,
					categories: resultatoGeral.map((item) => item.motorista),
				},
			},
			series: [
				{
					name: title,
					data: resultatoGeral.map((item) =>
						Number(item.valorTotal).toFixed(3)
					),
				},
			],
			length: resultatoGeral.length < 7 ? 300 : 500
		}));

		setTimeout(() => setLoading(false), 1000);
	}, [arrayData, arrayDataFiltered]);

	const [showAsList, setShowAsList] = useState({show: false, cargas: arrayDataFiltered || arrayData})

	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center pb-2 justify-content-between">
				<span style={{ marginRight: "10px", font: "inherit" }}>
					<span>{title}</span>
					{loading ? <Spinner animation="border" size="sm" /> : ""}
				</span>
				<i className="bi bi-boxes" style={{cursor: 'pointer'}} title="Listar Cargas" onClick={() => setShowAsList({show: true, cargas: arrayDataFiltered || arrayData})}></i>
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica`}>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
							height={options.length}
						/>
					</div>
				)}
			</Card.Body>
			{showAsList.show ? <ModalListarCargas {...showAsList} setShow={setShowAsList} /> :""}
		</Card>
	);
}

function ModalListarCargas({show, setShow, cargas}) {
	
	const [indexList, setIndexList] = useState(100);
	const [filteredTable, setFilteredTable] = useState(cargas);
	const [filters, setFilters] = useState([]);
	const [caminhao, setCaminhao] = useState([]);
	const [condutores, setCondutores] = useState([]);
	const [filtersNew, setFiltersNew] = useState({});

    const [headList, setHead] = useState([
		'index',
		'id',
		'id_filial',
		'nome',
		'tipo_carga',
		'peso_total',
		'volume_total',
		'metros_quadrados',
		'caminhao',
		'motorista',
		'qtde_pedidos',
		'manifesto',
		'status_manifesto',
	])
    const [pedidoWasChanged, setPedidoWasChanged] = useState(0)
	const tbodyRef = createRef();
	const tableRef = createRef();


    const handlerSimpleClose = () => {
        if (pedidoWasChanged > 1) {
            window.location.reload()
            return
        }
        setShow(last => ({ error: { flag: false } }))
    }
	/* scrollbar */
	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});
	/* filtros */
	
	useEffect(() => {
		if (!filters.length) {
			setIndexList(100);
			setFilteredTable(cargas);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [cargas];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                      Lista completa de cargas
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
					{/* <pre>{JSON.stringify(cargas[0], null, 2)}</pre> */}
					<div className="section-filters-table">
						<div className="card-header">
							<h3>Filtros Selecionados</h3>
						</div>
						<Row className="mt-2 row-filters">
							<Col>
								<div>
									{filters.map((item, key) =>
										Object.keys(item).map((key, index) => {
											return (
												<span className="filters-card" key={index}>
													<span className="filters">
														<span className="title">
															{key}: {item[key]}
														</span>
														<i
															className="bi bi-x-lg"
															onClick={onClickRemoveFilter}
														></i>
													</span>
												</span>
											);
										})
									)}
								</div>
							</Col>
						</Row>
					</div>
					<div className="section-filters-table">
						<Row>
							<Col>
								{/* <ScrollbarLeft
									tableRef={tableRef}
									coluna2={4}
									maxima_distancia={150}
								/> */}
								<div
									className=" responsive-table tes"
									ref={tableRef}
									style={{ position: "relative", height: 350, maxHeight: "unset" }}
								>
										<Table hover size="sm">
											<thead style={{ zIndex: "49" }}>
												<HeadTable
													arrayValues={[...cargas]}
													headerList={headList}
													setArrayFilters={setFiltersNew}
													arrayFilters={filtersNew}
													setFiltersOld={setFilters}
													filtersOld={filters}
												/>
												<tr style={{backgroundColor: '#80808061'}}>
													<th>Totais</th>
													<th>{filteredTable.length}</th>
													<th>{unicos(filteredTable, 'id_filial').length}</th>
													<th> - </th>
													<th>{unicos(filteredTable, 'tipo_carga').length}</th>
													<th>{numerosEditadoBR(filteredTable.reduce((p, c) => p + c.peso_total, 0))}</th>
													<th>{numerosEditadoBR(filteredTable.reduce((p, c) => p + c.volume_total, 0))}</th>
													<th>{numerosEditadoBR(filteredTable.reduce((p, c) => p + c.metros_quadrados, 0))}</th>
													<th>{unicos(filteredTable, 'caminhao').length}</th>
													<th>{unicos(filteredTable, 'motorista').length}</th>
													<th>{unicos(filteredTable, 'qtde_pedidos').length}</th>
													<th>{unicos(filteredTable, 'manifesto').length}</th>
													<th>{unicos(filteredTable, 'status_manifesto').length}</th>
												</tr>
											</thead>
											<tbody ref={tbodyRef}>
												{
													filteredTable.map((item, index, self) => {
														if (index < indexList) {
															return (
																<BodyRowCargas
																	key={index}
																	bodyList={item}
																	headList={headList}
																	index={index}
																	tableLength={self.length}
																	setArrayFilters={setFiltersNew}
																/>
															);
														}
													})
												}
											</tbody>
										</Table>
								</div>
							</Col>
						</Row>
					</div>
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border">
                    <Button variant='secondary' onClick={handlerSimpleClose}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
