import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
	apenasNumeros,
	getAllData,
	getData,
	getTableColumns,
	insertPedidos,
	insertPedidosManual,
	numerosDecimal,
	valorMoeda,
	verificaTipoUsuario,
} from "../../services/Servicos";
import { ListarRomaneios } from "../planoLogistica/romaneios/Romaneios";
import { useDispatch, useSelector } from "react-redux";

import "./Modal.scss";
import ModalErrorPretty from "./ModalErrorPretty";
import BotaoSalvar from "../botoes/BotaoSalvar";
import { getErrorMessage, isSuporte } from "../../services/helpers";

export default function ModalListarRomaneios({
	msg,
	title,
	show,
	setShow,
	msgButton,
	setCarga,
	carga,
}) {
	const [headList, setHead] = useState([]);
	const [romaneioFiltered, setRomaneioFiltered] = useState([]);
	const [newPedido, setNewPedido] = useState({show: false})
	const user = useSelector((state) => state.userSlice);

	const updateColumns = async () => {
		const romaneiosGet = await getTableColumns("CargasPedidos");

		let headList = [
			"index", 
			"admin",
			"status",
			"nome_vendedor",
			"tipo",
			...Object.keys(romaneiosGet.data)
				.filter((item) => item !== "status")
				.filter((item) => item !== "data_cancelamento")
				.filter((item) => item !== "nome_vendedor")
				.filter((item) => item !== "created_at")
				.filter((item) => item !== "updated_at")
				.filter((item) => item !== "qr_code_id")
				.filter((key) => key !== "cpf_responsavel")
				.filter((key) => key !== "tipo"),
		];
		setHead((last) => [...last, ...headList]);
		
	};


	useLayoutEffect(() => {
		updateColumns();
	}, []);
	useLayoutEffect(() => {
		const call = () => setNewPedido({show: false} )	
		if(newPedido?.consutingAgain){
			setTimeout(call, 200)
		}
		
	}, [newPedido?.consutingAgain]);


	const handlerSimpleClose = () => {
		// window.location.reload()
		setShow((last) => ({ error: { flag: false } }));
	};

	const handlerNewPedido = () => {
		setNewPedido({body: {cargas_id: carga}, show: true} )
	}

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>{title}:</span>
						<span>
							<b>{carga}</b>
						</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!
					newPedido?.consutingAgain && <ListarRomaneios
						headList={headList}
						height={400}
						carga_id={carga}
						romaneiosList={romaneioFiltered}
					/>
					}	
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					{
					isSuporte(user.email) ? 
						<Button variant="primary" onClick={handlerNewPedido}>
							Lançar Pedido
						</Button>
						: ""
					}
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Sair
					</Button>
				</Modal.Footer>
			</Modal>
			{newPedido.show ? 
				<ModalNewPedido {...newPedido} setShow={setNewPedido}/> : ''
			}
		</>
	);
}

function ModalNewPedido({
	show,
	setShow,
	body,
}) {
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;

	const handlerSimpleClose = () => {
		setShow({ show: false, consutingAgain: true });
	};

	const [newPedido, setNewPedido] = useState({...body, valor_total: valorMoeda(0), peso_nf: 0.00})
	const fnChangeStringNumberToNumber = (num) => String(num).replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.').trim()
	const fnValidation = async () => {
		if(!newPedido.numero_pedido) throw new Error('Numero do pedido não informado.')
		if(!newPedido.id_filial) throw new Error('Filial não selecionada.')
		if(!newPedido.emissao) throw new Error('Data da emissao não informada.')
		if(!newPedido.nome_razao) throw new Error('Razão social não informada.')
		if(!newPedido.cidade) throw new Error('Cidade não informada.')
		if(!newPedido.valor_total) throw new Error('Valor da nota não informado.')
		if(!newPedido.chave_nfe) throw new Error('Chave da nota não informada.')
		if(newPedido?.chave_nfe.length < 11 * 4) throw new Error(`CHAVE NFE contem apenas ${newPedido.chave_nfe.length} caracteres. O permitido é ${11 * 4}`)
		if(!newPedido.peso_nf) throw new Error('Peso da nota não informado.')
		if(!newPedido.tipo) throw new Error('Tipo do pedido não informadO.')

	}
	const handlerAddNewPedido = async (e) => {

		setBtnSalvar({ msg: "Aguarde", style: "warning" });
		try {
			await insertPedidosManual({
				...newPedido,
				valor_total: fnChangeStringNumberToNumber(newPedido.valor_total),
				peso_nf: fnChangeStringNumberToNumber(newPedido.peso_nf),
			})
			setBtnSalvar({ msg: "Concluido", style: "success" });
			setTimeout(handlerSimpleClose, 1000)
		} catch (error) {
			console.log(error)
			setError({show: true, message: getErrorMessage(error)})
		} 

	}

	const onChangeInputPadrao = (e) => {
		const value = e.target.value
		const parameter = e.target.name
		let _newPedido = {...newPedido}


		if (['valor_total'].includes(parameter)) {
			_newPedido[parameter] = valorMoeda(
				numerosDecimal(apenasNumeros(value)).replace(",", ".")
			).toLocaleString("pt-br", { minimumFractionDigits: 2 })
		} else if (['peso_nf'].includes(parameter)) {
			_newPedido[parameter] = Number(
				numerosDecimal(apenasNumeros(value)).replace(",", ".")
			).toLocaleString("pt-br", { minimumFractionDigits: 2 })
		} else {
			_newPedido[parameter] = value.toUpperCase()
		}

		setNewPedido(_newPedido);
	};
	const [error, setError] = useState({show: false, message: ''});
	const [btnSalvar, setBtnSalvar] = useState({
		style: "primary",
		msg: "Criar Pedido",
	});


	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Criar novo pedido para carga <b>{newPedido.carga}</b>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className="my-4">
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Numero Pedido:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Control type="text" 
									name="numero_pedido"
									onChange={onChangeInputPadrao}
									value={newPedido.numero_pedido ?? ''}
									required
								/>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Filial:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Select
									name="id_filial"
									onChange={onChangeInputPadrao}
									value={newPedido.id_filial ?? ''}
									required
								>
								<option>Escolha uma filial</option>
									{filiaisList.map((item, index) => (
										<option key={index} value={item.idFilial}>
											{item.identificacaoInterna}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Emissão:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Control
									name="emissao"
									onChange={onChangeInputPadrao}
									value={newPedido.emissao}
									required
									type="date"
								/>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Razão Social:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Control
									name="nome_razao"
									onChange={onChangeInputPadrao}
									value={newPedido.nome_razao ?? ''}
									required
								/>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Cidade:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Control
									name="cidade"
									onChange={onChangeInputPadrao}
									value={newPedido.cidade ?? ''}
									required
								/>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Valor Total:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Control
									name="valor_total"
									onChange={onChangeInputPadrao}
									value={newPedido.valor_total ?? 0}
									required
								/>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Carga:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Control
									name="cargas_id"
									value={newPedido.cargas_id}
									required
									disabled
								/>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Chave NFE:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Control
									name="chave_nfe"
									value={newPedido.chave_nfe ?? ''}
									onChange={onChangeInputPadrao}
									required
									maxLength={11 * 4}
								/>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Peso Total:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Control
									name="peso_nf"
									value={newPedido.peso_nf}
									required
									onChange={onChangeInputPadrao}
								/>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col sm='3'>
								<Form.Label>Tipo do Pedido:</Form.Label>
							</Col>
							<Col sm='9'>
								<Form.Select onChange={onChangeInputPadrao} name="tipo" value={newPedido.tipo} required>
									<option value =''></option>    
									<option value ='ENTREGAR AO CLIENTE'>Entregar ao Cliente</option>    
									<option value ='ENTREGAR NA LOJA'>Entregar na Loja</option>    
									<option value ='RETIRAR NA LOJA'>Cliente Retira na Loja</option>    
								</Form.Select>
							</Col>
						</Row>
						<div className="d-flex mt-5 column-gap-3">
							<BotaoSalvar
								style={btnSalvar.style}
								msg={btnSalvar.msg}
								fnRetorno={handlerAddNewPedido}
								validation={fnValidation}
							/>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Sair
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
			
			{error.show ? <ModalErrorPretty setShow={setError} {...error} />:""}
		</>
	);
}