import { Button, Spinner } from "react-bootstrap";
import { getErrorMessage, throttleFunc } from "../../services/helpers";
import ModalErrorPretty from "../modal/ModalErrorPretty";
import { useState } from "react";

export default function BotaoSalvar({ msg, style, fnRetorno, type = 'button', validation = null}){

    const [error, setError] = useState({show: false, message: ''});
    const verifyThrottleFunc = async () => {
        try {
            if(validation){
                await validation()
            }
            const th = throttleFunc(fnRetorno, 5000)()
            if(th?.includes('em processamento')) throw new Error('Aguarde alguns segundos enquanto o procedimento é relizado...')
        } catch (error) {
            setError({show: true, message: getErrorMessage(error)})
        }
    }
       
    return (
        <>
            <Button variant={style} onClick={ verifyThrottleFunc } type={type}>
                {msg}
                <span className="ms-2">
                    {style == 'success' ? <i className="bi bi-check2" style={{color:"white"}}></i>:''}
                    {style == 'warning' ? <Spinner color="black" animation="border" size="sm"/>:''}
                </span>
            </Button>
            
            {error.show ? <ModalErrorPretty setShow={setError} {...error} />:""}
        </>
    )
}