import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Col, Nav, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import Loading from "../../loading/Loading";
import {
	updateEmbalagensList,
	updateManifestosList,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getDateInitMonth,
	getTableColumns,
} from "../../../services/Servicos";
import HeadTable from "../../table/head/HeadRow";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import BodyRowManifestos from "../../table/body/BodyRowManifestos";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import { Outlet } from "react-router-dom";
import ManifestoControle from "./ManifestoControle";
import ManifestosNaoEncerrados from "./ManifestosNaoEncerrados";

const tableRef = createRef();
const tbodyRef = createRef();

const buttomStyle = {
	textAlign: "center",
	padding: "15px",
	boxShadow: "0 5px 10px #00000021",
	borderRadius: "5px",
	marginBottom: "0.8rem",
};

export default function Manifestos() {
	const [selected, setSelected] = useState("Geral");

	const onChangePage = (page) => {
		setSelected(page);
	};
	return (
		<PlanoLositicaLayout>
			<div className="card-header d-flex align-items-center">
				<h3 style={{ marginBottom: 0, marginRight: "5px" }}>
					Tabela de Manifestos - {selected != "Geral"? "Não Encerrados" : "Lista Geral"}
				</h3>
				{/* {loading ? <Spinner size="sm" animation="border" /> : ""} */}
			</div>

			<Row>
				<Col sm="12" md="3">
					<Button
						onClick={() => onChangePage("Geral")}
						style={buttomStyle}
						variant="info"
						className={selected == "Geral" ? "bg-dark text-light" : ""}
					>
						{" "}
						<i className="bi bi-list"></i> Lista de Manifestos
					</Button>
					<Button
						onClick={() => onChangePage("Não Encerrado")}
						style={buttomStyle}
						variant="info"
						className={selected == "Não Encerrado" ? "bg-dark text-light" : ""}
					>
						{" "}
						<i className="bi bi-x-lg"></i> Não Encerrados
					</Button>
				</Col>
				<Col sm='12' md='9'>
					{selected != "Geral" ? <ManifestosNaoEncerrados /> : <ManifestoControle />}
				</Col>
			</Row>
		</PlanoLositicaLayout>
	);
}
